import request from '@/utils/request'


// 获得视频分页
export function getVideoPage(query) {
    return request({
        url: '/ich/video/page',
        method: 'get',
        params: query
    })
}

// 获得直播分页
export function getLiveDetail(id) {
    return request({
        url: '/ich/video/get?id='+id,
        method: 'get',
        
    })
}